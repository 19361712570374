export const featureDTO = (data, tenantFeatures) => {
    const tenantFeature = tenantFeatures.find(el => el.name === data.name);
    const dto = {
        ID: {
            default: data.ID || '',
            hidden: true,
        },
        deleted: {
            default: data.deleted,
            hidden: true,
        },
        name: {
            label: "name",
            default: data.name,
            name: data.name,
            rule: ['required'],
        },
        category: {
            label: "category",
            default: data.category,
            category: data.category,
            rule: ['required'],
        },
        shop_id: {
            hidden: true,
            default: data.shop_id || '',
            shop_id: data.shop_id || '',
        },
        flow_id: {
            hidden: true,
            default: data.flow_id || '',
            flow_id: data.flow_id || '',
        },
        brand_id: {
            hidden: true,
            default: data.brand_id || '',
            brand_id: data.brand_id || '',
        },
    }
    tenantFeature && Object.keys(tenantFeature.functional || {}).map(el => {
        dto[el] = {
            label: [el],
            originalData: tenantFeature.functional[el],
            showOnlyOriginal: data.overrides?.[el] === undefined ? true : false,
            default: data.overrides?.[el] || '',
            [el]: data.overrides?.[el] || '',
            type: "functional"
        }
    })
    return dto
}