const PANEL_ENDPOINT = {
    GET_TENANTS: "tenants",
    GET_TENANT_FEATURE: "tenants/:tenant_id/features",
    TENANT_FEATURE_DETAIL: "tenants/:tenant_id/features/:feature_id",
    GET_TENANT_SHOP: ":tenant_id/shops",
    TENANT_SHOP_DETAIL: ":tenant_id/shops/:shop_id",
    GET_TENANT_USER: ":tenant_id/users",
    TENANT_USER_DETAIL: ":tenant_id/users/:user_id",
    GET_SHOP_BRAND: ":tenant_id/users",
    GET_BRAND: ":tenant_id/shops/:shop_id/brands",
    BRAND_DETAIL: ":tenant_id/shops/:shop_id/brands/:brand_id",
    GET_FLOW: ":tenant_id/shops/:shop_id/brands/:brand_id/flows",
    FLOW_DETAIL: ":tenant_id/shops/:shop_id/brands/:brand_id/flows/:flow_id",
    GET_FEATURE: ":tenant_id/shops/:shop_id/brands/:brand_id/flows/:flow_id/features",
    FEATURE_DETAIL: ":tenant_id/shops/:shop_id/brands/:brand_id/flows/:flow_id/features/:feature_id",
}

export default PANEL_ENDPOINT;