<template>
  <v-app-bar
    app
    :class="$store.state.layout.theme"
    class="header"
  >
    <div class="header__wrap flex-center">
      <div class="header__item">
        <v-menu
          bottom
          offset-y
          min-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
            >
              {{ username }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in menu"
              :key="i"
              class="cursor-pointer"
            >
              <v-list-item-title
                class="flex-center flex-nowrap"
                @click="item.onClick"
              >
                <v-icon
                  :style="{ flex: 0, marginRight: '4px' }"
                  v-text="item.icon"
                />
                {{ $t(item.text) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="header__item-center">
        <strong>{{ $t("label.admin") }}</strong>
      </div>
      <div class="flex-center header__item-select">
        <v-select
          v-model="locale"
          :items="languages"
          item-value="language"
          item-text="flag"
          return-object
        >
          <template
            slot="selection"
            slot-scope="{ item, index }"
          >
            <button :key="index">
              <flag
                :iso="item.flag"
                :squared="false"
              />
              {{ item.title }}
            </button>
          </template>
          <template
            slot="item"
            slot-scope="{ item, index }"
          >
            <button :key="index">
              <flag
                :iso="item.flag"
                :squared="false"
              />
              {{ item.title }}
            </button>
          </template>
        </v-select>
      </div>
      <div class="header__item flex-0">
        <v-menu
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              plain
              :ripple="false"
              icon
              v-on="on"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in themeColors"
              :key="i"
              @click="onChangeTheme(item)"
            >
              <v-list-item-title class="flex-center">
                <div
                  :class="item.color || item.title"
                  class="text-center bordered"
                >
                  <span class="hidden">ipsip</span>
                </div>
                            &nbsp;
                {{ $t('label.'+item.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import themeColors from '../../constants/themes';
import languages from '../../constants/languages';

export default {
    name: "Header",
    data: () => ({
        themeColors: themeColors,
        languages: languages,
        locale: {}
    }),
    computed: {
        menu: function(){
            let app = this;
            return [
                {
                    icon: 'mdi-logout',
                    text: 'label.logOut',
                    onClick: function() {
                        app.$store.dispatch("logout");
                    }
                }
            ]
        },
        ...mapState({
            username: (state) => state.auth?.user?.user,
        }),
    },
    watch: {
        locale() {
            this.$i18n.locale = this.locale?.language;
        },
    },
    mounted(){
        this.locale = this.languages[0];
    },
    methods: {
        onChangeTheme(themeItem){
            this.$store.dispatch("layout/onChangeTheme", themeItem.title);
            if(themeItem.title === "dark"){
                this.$vuetify.theme.dark = true;
            } else if (themeItem.title === "light"){
                this.$vuetify.theme.dark = false;
            }
        }
    }
};
</script>

<style lang="scss">
@import '@/styles/theme/variable.scss';

.header{
    height: $header-height;
    .v-toolbar__content{
        padding-right: 0;
    }
    .header__wrap{
        justify-content: space-between;
        width: 100%;
    }
    .header__item, .header__item-center{
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .header__item:not(.header__item-center){
        flex: 1;
    }
    .header__item-center{
        flex: 2;
        text-align: center;
    }
    .header__item-select{
        margin-top: 14px;
        width: 220px;
        flex: 1;
        .header__item{
            margin-left: 10px;
            margin-top: 0;
        }
    }
}
</style>
