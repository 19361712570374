<template>
  <v-treeview
    item-key="ID"
    :active.sync="active"
    :items="list"
    :open.sync="open"
    activatable
    color="warning"
    open-on-click
    return-object
    transition
    :load-children="getList"
  >
    <template v-slot:label="{item}">
      <div class="flex-center justify-space-between">
        <div class="flex-center">
          <v-icon
            v-if="item.is_account"
            class="mr-1"
          >
            mdi-account
          </v-icon>
          <div>{{ item.name }}</div>
        </div>
        <v-icon
          v-if="item.children_tree && item.is_drawer"
          class="ml-1"
        >
          mdi-arrow-right
        </v-icon>
      </div>
    </template>
  </v-treeview>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Treeview',
    props: {
      getList: Function,
      list: Array,
    },
    data(){
        return {
            open: [],
            active: [],
        }
    },
    computed: mapState({
      storeActive: (state) => state.panel?.activePanelItems,
    }),
    watch: {
      active: function(newValue) {
          this.$emit('onChangeActive', [...newValue, ...this.storeActive])
      }
    },
}
</script>