export const panelType = {
    tenant: 'tenant',
    tenantShop: 'tenantShop',
    tenantShopDetail: 'tenantShopDetail',
    tenantFeature: 'tenantFeature',
    tenantFeatureDetail: 'tenantFeatureDetail',
    tenantUser: 'tenantUser',
    tenantUserDetail: 'tenantUserDetail',
    brandDetail: 'brandDetail',
    brandList: 'brandList',
    flowList: 'flowList',
    flowDetail: 'flowDetail',
    featureDetail: 'featureDetail',
    featureList: 'featureList',
    panelForm: 'panelForm',
}