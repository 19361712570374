import './httpHandle'
import AdminLayout from './views/layout/Admin.vue';
import App from "./App.vue";
import AuthLayout from './views/layout/Auth.vue';
import FlagIcon from 'vue-flag-icon';
import Vue from "vue";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import vuelidate from 'vuelidate';
import vuetify from "./plugins/vuetify";
import VueTelInput from 'vue-tel-input';
import _ from 'lodash';
import 'vue-tel-input/dist/vue-tel-input.css';

import './styles/style.scss' //must included here to overwrite theme css

Vue.use(FlagIcon);
Vue.use(VueTelInput);
Vue.use(vuelidate);
Vue.set(Vue.prototype, '_', _);
Vue.config.productionTip = false;

Vue.component('admin-layout', AdminLayout);
Vue.component('auth-layout', AuthLayout);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");