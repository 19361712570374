import Vue from "vue";
import Vuex from "vuex";
import alert from './alert';
import auth from './modules/auth';
import loader from './loader';
import * as panel from './modules/panel/index';
import layout from './layout';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: process.env.PACKAGE_VERSION
  },
  modules: {
    alert,
    loader,
    layout,

    ...panel,
    auth,
  },
});
