export const countryCities = [
    {
        value: 'VN',
        text: 'Viet Nam',
        city: [{
            value: '1',
            text: 'Hanoi',
        }, {
            value: '2',
            text: 'Ho Chi Minh'
        }]
    }, 
    {
        value: 'FR',
        text: 'France',
        city: [{
            value: 'LORMONT',
            text: 'LORMONT'
        },
        {
            value: 'MALAKOFF',
            text: 'MALAKOFF'
        }, {
            value: 'VENDARGUES',
            text: 'VENDARGUES'
        }, {
            value: 'COLMAR',
            text: 'COLMAR'
        }, {
            value: 'LAXOU',
            text: 'LAXOU'
        }, {
            value: 'LE BOUSCAT',
            text: 'LE BOUSCAT',
        }, {
            value: 'NANTERRE',
            text: 'NANTERRE',
        }, {
            value: 'JOINVILLE LE PONT',
            text: 'JOINVILLE LE PONT',
        }, {
            value: 'MERIGNAC',
            text: 'MERIGNAC'
        }]
    }
];