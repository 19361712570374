<template>
  <v-col class="border-left panel__right">
    <v-scroll-y-transition mode="out-in">
      <div
        v-if="loadDetail"
        class="min-vh-full flex-center-full"
      >
        <v-progress-circular
          :size="50"
          color="primary"
          class="my-3 w-100"
          indeterminate
        />
      </div>
      <div
        v-else-if="!selectedTopic"
        class="min-vh-full flex-center-full text-h6 grey--text text--lighten-1 font-weight-light"
      >
        {{ $t("comments.selectItem") }}
      </div>
      <v-card
        v-else
        :key="selectedTopic.ID"
        class="pt-6 pb-13 mx-auto"
        flat
      >
        <v-card-text class="text-center">
          <v-avatar size="88">
            <v-img
              :src="require('@/assets/image/tenant.jpg')"
              class="mb-6"
            />
          </v-avatar>
          <h3 class="text-h5 mb-2">
            {{ selectedTopic.tenant.name }}
          </h3>
          <div class="blue--text mb-2">
            {{ selectedTopic.tenant.contact }}
          </div>
          <div class="blue--text subheading font-weight-bold">
            {{ selectedTopic.tenant.shortName }}
          </div>
        </v-card-text>

        <!-- FORM -->
        <v-divider />
        <InformationForm />
        <!-- END FORM -->
      </v-card>
    </v-scroll-y-transition>
  </v-col>
</template>

<script>
import {
    mapState
} from 'vuex';
import formMixin from '@/mixin/formMixin.js';
import InformationForm from './InformationForm.vue';
// import Treeview from '../common/Treeview.vue';

export default {
    name: 'PanelTree',
    components: {
        InformationForm,
    },
    mixins: [formMixin],
    data(){
        return {
            loadDetail: false,
        }
    },
    computed: mapState({
        selectedTopic(state) {
            const { panel } = state;
            let selectedItem = panel.activePanelItems[0];
            const isAlreadyExist = panel.detailItems.find(el => el.endpoint?.default === selectedItem.endpoint && el.name?.default === selectedItem.name);
            if(!isAlreadyExist && selectedItem?.endpoint?.indexOf("detail") > -1 && !panel.isBack){
                this.getDetail(selectedItem);
            }
            return selectedItem;
        },
        stepPanel: state => state.panel.stepPanel,
        stepPanelIndex: state => state.panel.stepPanelIndex,
    }),
    methods: {
        //set list to show in panelleft
        async getDetail(item = {}){
            this.loadDetail = true;
            console.log(item.tenant, this.$store.state.panel?.selectedTenant)
            item.tenant = item.tenant || this.$store.state.panel?.selectedTenant;
            const { list } = await this.$store.dispatch("getTopicDetail", item);
            if(item.children_tree){
              const firstChild = Object.keys(item.children_tree)?.[0];
              //set current stepPanel
              if(item.children_tree[firstChild]?.is_drawer){
                this.$store.commit("setStepPanel",
                  {
                    [firstChild]: {
                      title: this._.capitalize(item.name) + ' ' + firstChild,
                      list: list.map(el => ({...el, ...item.children_tree[firstChild]})),
                      key: firstChild
                    }
                  }
                )
                this.$store.commit("setStepPanelIndex", this.stepPanelIndex +1)
              }
            }
            this.loadDetail = false;
        },
    }
}
</script>
<style lang="scss">
.panel__right{
    margin-left: 33.33%;
}
</style>