import 'vuetify/dist/vuetify.min.css'

import Vue from "vue";
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true }, //overwrite theme colors
  },
  icons: {
    // iconfont: "mdi", // default - only for display purposes
  },
});
