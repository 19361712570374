export default class StoreUtil {
  /**
   * init the state.
   * @return {Object} default state
   */
  static state() {
    return {
      data: null,
      status: {
        pending: false,
        success: false,
        fail: false,
        error: null
      }
    };
  }

  static async callMethod(state, service){
    this._mutationPending(state);
    const res = await service;
    if(res.status !== 200){
      this._mutationFail(state, res)
    } else {
      this._mutationSuccess(state, res)
    }
  }
  /**
   * @param {Object} state - the state to update
   * @param {Object,Error} data - data to update with
   * @returns {Object} state after update
   */

  static updateState(state, data = null) {
    //TODO: ADD CHECK to verify that state
    if (data === null) {
      // PENDING
      return this._mutationPending({ ...state });
    }
    //SUCCESS or FAIL
    return data instanceof Error
      ? this._mutationFail({ ...state }, data)
      : this._mutationSuccess({ ...state }, data);
  }

  /**
   * @param {Object} state - the status to be put in pending state
   * @returns {Object} updated state
   */
  static _mutationPending(state) {
    state.data = null;
    state.status.pending = true;
    state.status.success = false;
    state.status.fail = false;
    state.status.error = null;
    return state;
  }

  /**
   * @param {Object} state - the status to be put in success state
   * @param {Object,array} - data to update with
   * @returns {Object} updated state
   */
  static _mutationSuccess(state, res) {
    state.data = typeof res === "object" ? res?.data : null;
    state.status.pending = false;
    state.status.success = true;
    state.status.fail = false;
    state.status.error = null;
    return state;
  }

  /**
   * @param {Object} state - the status to be put in success state
   * @param {Object,array} - data to update with
   * @returns {object} updated state
   */
  static _mutationFail(state, data) {
    state.data = null;
    state.status.pending = false;
    state.status.success = false;
    state.status.fail = true;
    state.status.error = data;
    return state;
  }
}