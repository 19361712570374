import PANEL_ENDPOINT from '../endpoint/panel.endpoint';
import { Utils } from '../../utils';
import { shopDTO } from '../../dto/shopDTO';
import { tenantDTOList } from '../../dto/tenantDTO';
import { types } from '../../store/loader';
import { userDTO } from '../../dto/userDTO';
import { brandDTO } from '../../dto/brandDTO';
import { flowDTO } from '../../dto/flowDTO';
import { featureDTO } from '../../dto/featureDTO';
import { featureTenantDTO } from '../../dto/featureTenantDTO';
import { panelType } from '../../store/modules/panel/constant';

export const typesPending = {
    [panelType.tenant]: types(panelType.tenant),
    [panelType.tenantShop]: types(panelType.tenantShop),
    [panelType.tenantShopDetail]: types(panelType.tenantShopDetail),
    [panelType.tenantFeature]: types(panelType.tenantFeature),
    [panelType.tenantFeatureDetail]: types(panelType.tenantFeatureDetail),
    [panelType.tenantUser]: types(panelType.tenantUser),
    [panelType.tenantUserDetail]: types(panelType.tenantUserDetail),
    [panelType.brandDetail]: types(panelType.brandDetail),
    [panelType.brandList]: types(panelType.brandList),
    [panelType.flowList]: types(panelType.flowList),
    [panelType.flowDetail]: types(panelType.flowDetail),
    [panelType.featureDetail]: types(panelType.featureDetail),
    [panelType.featureList]: types(panelType.featureList),
    [panelType.panelForm]: types(panelType.panelForm),
}
export const getTenantList = async () => {
    const res = await Utils.wrapperRequestGet(typesPending.tenant, PANEL_ENDPOINT.GET_TENANTS);
    return tenantDTOList(res)?.items;
}
export const getShop = async (tenantID) => {
    const res = await Utils.wrapperRequestGet(typesPending.tenantShop, PANEL_ENDPOINT.GET_TENANT_SHOP, {
        tenant_id: tenantID
    });
    return res;
}

export const getShopDetail = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.tenantShop, PANEL_ENDPOINT.TENANT_SHOP_DETAIL, {
        tenant_id: item.tenant?.ID,
        shop_id: item.ID,
    });
    return shopDTO(res);
}

export const getTenantFeature = async (tenantID) => {
    const res = await Utils.wrapperRequestGet(typesPending.tenantFeature, PANEL_ENDPOINT.GET_TENANT_FEATURE, {
        tenant_id: tenantID
    });
    return res;
}

export const getTenantFeatureDetail = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.tenantFeatureDetail, PANEL_ENDPOINT.TENANT_FEATURE_DETAIL, {
        tenant_id: item.tenant?.ID,
        feature_id: item.ID,
    });
    return featureTenantDTO(res);
}

export const getUser = async (tenantID) => {
    const res = await Utils.wrapperRequestGet(typesPending.tenantUser, PANEL_ENDPOINT.GET_TENANT_USER, {
        tenant_id: tenantID
    });
    return res;
}

export const getUserDetail = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.tenantUserDetail, PANEL_ENDPOINT.TENANT_USER_DETAIL, {
        tenant_id: item.tenant?.ID,
        user_id: item.ID,
    });
    return userDTO(res);
}

export const getBrand = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.brandList, PANEL_ENDPOINT.GET_BRAND, {
        tenant_id: item.tenant?.ID,
        shop_id: item.ID
    });
    return res;
}

export const getBrandDetail = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.brandDetail, PANEL_ENDPOINT.BRAND_DETAIL, {
        tenant_id: item.tenant?.ID,
        shop_id: item.shop_id,
        brand_id: item.ID
    });
    return brandDTO(res);
}

export const getFlow = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.flowList, PANEL_ENDPOINT.GET_FLOW, {
        tenant_id: item.tenant?.ID,
        shop_id: item.shop_id,
        brand_id: item.ID
    });
    return res;
}

export const getFlowDetail = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.flowDetail, PANEL_ENDPOINT.FLOW_DETAIL, {
        tenant_id: item.tenant?.ID,
        shop_id: item.shop_id,
        brand_id: item.brand_id,
        flow_id: item.ID
    });
    return flowDTO(res);
}

export const getFeature = async (item) => {
    const res = await Utils.wrapperRequestGet(typesPending.featureList, PANEL_ENDPOINT.GET_FEATURE, {
        tenant_id: item.tenant?.ID,
        shop_id: item.shop_id,
        brand_id: item.brand_id,
        flow_id: item.ID,
    });
    return res;
}

export const getFeatureDetail = async (item, tenantFeatures) => {
    const res = await Utils.wrapperRequestGet(typesPending.featureDetail, PANEL_ENDPOINT.FEATURE_DETAIL, {
        tenant_id: item.tenant?.ID,
        shop_id: item.shop_id,
        brand_id: item.brand_id,
        flow_id: item.flow_id,
        feature_id: item.ID
    });
    return featureDTO(res, tenantFeatures);
}

export const updateShop = async ({tenantID, params, data}) => {
    const res = await Utils.wrapperRequestPost(
        typesPending.panelForm, 
        PANEL_ENDPOINT.TENANT_SHOP_DETAIL, 
        {
            tenant_id: tenantID,
            shop_id: params.originalData?.ID,
        }, 
        data, 
        'put'
    )
    return res;
}

export const updateUser = async ({tenantID, params, data}) => {
    const res = await Utils.wrapperRequestPost(
        typesPending.panelForm, 
        PANEL_ENDPOINT.TENANT_USER_DETAIL, 
        {
            tenant_id: tenantID,
            user_id: params.originalData?.ID,
        }, 
        data, 
        'put'
    )
    return res;
}

export const updateBrand = async ({tenantID, params, data}) => {
    const res = await Utils.wrapperRequestPost(
        typesPending.panelForm, 
        PANEL_ENDPOINT.BRAND_DETAIL, 
        {
            tenant_id: tenantID,
            shop_id: params.originalData?.shop_id,
            brand_id: params.originalData?.ID,
        }, 
        data, 
        'put'
    )
    return res;
}

export const updateFlow = async ({tenantID, params, data}) => {
    const res = await Utils.wrapperRequestPost(
        typesPending.panelForm, 
        PANEL_ENDPOINT.FLOW_DETAIL, 
        {
            tenant_id: tenantID,
            shop_id: params.originalData?.shop_id,
            brand_id: params.originalData?.brand_id,
            flow_id: params.originalData?.ID,
        }, 
        data, 
        'put'
    )
    return res;
}

export const updateFeature = async ({tenantID, params, data}) => {
    const res = await Utils.wrapperRequestPost(
        typesPending.panelForm, 
        PANEL_ENDPOINT.FEATURE_DETAIL, 
        {
            tenant_id: tenantID,
            shop_id: params.originalData?.shop_id,
            brand_id: params.originalData?.brand_id,
            flow_id: params.originalData?.flow_id,
            feature_id: params.originalData?.ID,
        }, 
        data, 
        'put'
    )
    return res;
}