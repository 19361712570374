export const flowDTO = data => {
    return {
        ID: {
            default: data.ID,
            hidden: true,
        },
        deleted: {
            default: data.deleted,
            hidden: true,
        },
        expiresAt: {
            default: data.expires_at,
            expiresAt: data.expires_at,
            hidden: true,
        },
        name: {
            label: "name",
            default: data.name,
            name: data.name,
            rule: ['required'],
        },
        shop_id: {
            hidden: true,
            default: data.shop_id || '',
            shop_id: data.shop_id || '',
        },
        flow_id: {
            hidden: true,
            default: data.flow_id || '',
            flow_id: data.flow_id || '',
        },
        brand_id: {
            hidden: true,
            default: data.brand_id || '',
            brand_id: data.brand_id || '',
        },
        params: {
            hidden: true,
            default: data.params,
            params: data.params,
        }
    }
}