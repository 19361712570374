export const brandDTO = data => {
    return {
        ID: {
            default: data.ID,
            hidden: true,
        },
        deleted: {
            default: data.deleted,
            hidden: true,
        },
        expires_at: {
            default: data.expires_at,
            expiresAt: data.expires_at,
            hidden: true,
        },
        name: {
            label: "name",
            default: data.name,
            name: data.name,
            rule: ['required'],
        },
        shop_id: {
            hidden: true,
            default: data.shop_id || '',
            shop_id: data.shop_id || '',
        },
    }
}