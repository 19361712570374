const layoutModule = {
    namespaced: true,
    state: {
        theme: "light",
    },
    mutations: {
        setTheme(state, {theme}){
            state.theme = theme;
        }
    },
    actions: {
        onChangeTheme(context, theme){
            context.commit("setTheme", {theme})
        },
    }
}

export default layoutModule;