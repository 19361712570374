<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card min-width="500">
      <v-card-title class="text-h6">
        <span>{{ currentTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="tenantName"
          class="mb-3"
          :rules="formRules.required"
          :label="$t('label.tenantName')"
          @keyup.enter="onSubmit"
        />
        <v-text-field
          v-model="username"
          class="mb-3"
          :rules="formRules.required"
          :label="$t('label.username')"
          @keyup.enter="onSubmit"
        />
        <v-text-field
          v-model="password"
          class="mb-3"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="formRules.required"
          :type="showPass ? 'text' : 'password'"
          :label="$t('label.password')"
          @keyup.enter="onSubmit"
          @click:append="showPass = !showPass"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="onSubmit"
        >
          {{ $t("label.login") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { typesPending } from '../../services/auth/auth.service';
import formMixin from '../../mixin/formMixin';
export default {
    name: "AuthCard",
    mixins: [formMixin],
    data() {
        return {
            currentTitle: this.$t("label.login"),
            tenantName: "",
            username: "",
            password: "",
            showPass: false,
        }
    },
    computed: mapState('loader', {
        loading: typesPending,
    }),
    methods: {
        async onSubmit(){
            this.validate();
            if(this.valid){
                await this.$store.dispatch("login", {
                    tenantName: this.tenantName,
                    username: this.username,
                    password: this.password,
                }).then((data) => {
                    if(data){
                        this.$router.push('/')
                    }
                })
            }
        }
    }
}
</script>
