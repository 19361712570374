export const tenantDTO = data => {
    const { ID } = data;
    const tenant = {
        ID: ID,
        deleted: data.deleted,
        name: data.name,
        contact: data.contact,
        short_name: data.short_name,
        is_account: true,
    }
    return {
        ...tenant,
        children: [
        {
            ID: "shop_" + ID,
            parent_id: ID,
            name: "Shops",
            tenant,
            endpoint: "shop",
            section: "shop",
            children_tree: { //children_tree for dynamic children >< static level should be written in children[]
                brand: {
                    endpoint: "brand_detail",
                    is_drawer: true,
                    section: "brand",
                    children_tree: {
                        flow: {
                            section: "flow",
                            endpoint: "flow_detail",
                            is_drawer: true,
                            children_tree: {
                                feature: {
                                    is_drawer: true,
                                    endpoint: "feature_detail",
                                    section: "feature",
                                }
                            }
                        }
                    },
                },
            },
            children: []
        }, {
            ID: "user_" + ID,
            tenant,
            parent_id: ID,
            name: "Users",
            endpoint: "user",
            children: [],
            section: "user",
        }, {
            ID: "feature_" + ID,
            tenant,
            parent_id: ID,
            name: "Features",
            endpoint: "tenant feature",
            children: [],
            section: "tenantFeature",
        }]
    }
}

export const tenantDTOList = (arr = []) => {
    return {
        items: Array.isArray(arr) ? arr.map(el => tenantDTO(el)) : [],
    }
}