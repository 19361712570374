<template>
  <v-container
    fluid
    class="pa-0"
  >
    <PanelTree />
  </v-container>
</template>

<script>
import PanelTree from '../../components/app/PanelTree.vue'

export default {
  name: 'Panel',
  components: {
    PanelTree,
  }
}
</script>