import { UPDATE_DETAIL } from '../../../httpHandle';
import * as PanelService from '../../../services/app/panel.service'
import { StoreUtil } from '../../../utils'
import { formMutation } from '../../constants'

const panelFormModule = {
    namespaced: true,
    state: StoreUtil.state(),
    mutations: {
        [formMutation.UPDATE_FORM](state, data){
            state = data;
        } 
    },
    actions: {
        async submitForm({ commit, state }, data){
            const res = await StoreUtil.callMethod(state, PanelService[UPDATE_DETAIL(data.params?.section)](data));
            commit(formMutation.UPDATE_FORM, res)
        }
    }
}

export default panelFormModule