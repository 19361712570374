import * as PanelService from "../../../services/app/panel.service";
import { GET_LIST, GET_DETAIL } from '../../../httpHandle'
import i18n from '../../../i18n'
import _ from "lodash";

const panelModule = {
    state: {
        detailItems: [],
        activePanelItems: [],
        stepPanel: {},
        stepPanelIndex: '',
        selectedTenant: null,
        tenantFeatures: {},
        isBack: false,
    },
    getters: {
        stepPanelArr: (state) => _.values(state.stepPanel)
    },
    mutations: {
        setIsBack: (state, isBack) => {
            state.isBack = isBack
        },
        setItems: (state, newItems) => {
            state.detailItems = newItems;
        },
        setActives: (state, ids) => {
            state.activePanelItems = ids
        },
        setStepPanel: (state, stepPanel) => {
            state.stepPanel = Object.assign({}, {...state.stepPanel, ...stepPanel})
        },
        setStepPanelIndex: (state, index) => {
            state.stepPanelIndex = index
        },
        setSelectedTenant: (state, tenant) => {
            state.selectedTenant = tenant
        },
        setTenantFeatures: (state, obj) => {
            state.tenantFeatures = Object.assign({}, {...state.tenantFeatures, ...obj})
        },
    },
    actions: {
        //get tenants
        async getTenantList(context){
            let list = await PanelService.getTenantList();
            context.commit("setStepPanel", {
                tenant: {
                    key: 'tenant',
                    title: i18n.tc('label.tenantList'),
                    list: list,
                }
            });
            context.commit("setStepPanelIndex", 0);
        },

        //get tenant's shops, users, features
        async getTopicList(context, item){
            let list = [];
            list = await PanelService[GET_LIST(item.endpoint)](item.parent_id);
            list.forEach(el => {
                if(item.children_tree){
                    Object.assign(el, {
                        is_drawer: true,
                        children_tree: item.children_tree,
                    })
                }
                Object.assign(el, {
                    tenant: item.tenant,
                    endpoint: item.endpoint + '_detail',
                    section: item.section,
                });
            });
            item.children = list;
            if(item.tenant){
                context.commit("setSelectedTenant", item.tenant)
            }
        },

        //get detail and list children
        async getTopicDetail({ commit, state }, item){
            let endpointTopic = item.endpoint?.split('_')?.[0];
            let list = [];
            let res = {};
            
            const tenantFeatures = state.tenantFeatures[item.tenant?.ID];
            if(endpointTopic === 'flow' && !tenantFeatures){
                const featureList = await PanelService.getTenantFeature(item.tenant?.ID) //get tenant feature to match with flow feature
                commit("setTenantFeatures",
                    {   
                        [item.tenant?.ID]: featureList
                    }
                )
                res = await PanelService.getFlowDetail(item) || {};
            } else if (endpointTopic === 'feature') {
                res = await PanelService.getFeatureDetail(item, tenantFeatures) || {};
            } else {
                res = await PanelService[GET_DETAIL(endpointTopic)](item) || {};
            }

            //get detail
            res.endpoint = {
                hidden: true,
                default: item.endpoint
            };
            res.section = {
                hidden: true,
                default: item.section
            };

            //if this endpoint already exist in arr detailItems, replace it
            const oldDetailIndex = state.detailItems.findIndex(el => el.endpoint?.default === res.endpoint?.default);
            if(oldDetailIndex !== -1){
                const arr = [...state.detailItems];
                arr[oldDetailIndex] = res;
                commit("setItems", arr)
            } else {
                commit("setItems", [res, ...state.detailItems])
            }

            //get children belongs to that topic
            if(item.children_tree){
                const firstChild = Object.keys(item.children_tree)?.[0]
                list = await PanelService[`get${_.capitalize(firstChild)}`](item) //getBrand, getFlow, getFeature
            }

            if(item.tenant){
                commit("setSelectedTenant", item.tenant)
            }
            
            return {detail: res, list};
        }
    }
}

export default panelModule;