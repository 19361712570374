export const featureTenantDTO = data => {
    const dto = {
        ID: {
            default: data.ID || '',
            hidden: true,
        },
        deleted: {
            default: data.deleted,
            hidden: true,
        },
        expires_at: {
            default: data.expires_at,
            expiresAt: data.expires_at,
            hidden: true,
        },
        name: {
            label: "name",
            default: data.name,
            name: data.name,
            rule: ['required'],
        },
        category: {
            label: "category",
            default: data.category,
            category: data.category,
            rule: ['required'],
        },
    }
    Object.keys(data.functional || {}).map(el => {
        dto[el] = {
            label: [el],
            originalData: data.functional?.[el] || '',
            showOnlyOriginal: data.override?.[el] === undefined ? true : false,
            default: data.override?.[el] || '',
            [el]: data.override?.[el] || '',
            type: "functional"
        }
    })
    Object.keys(data.technical || {}).map(el => {
        dto[el] = {
            label: [el],
            originalData: data.technical?.[el] || '',
            showOnlyOriginal: data.override?.[el] === undefined ? true : false,
            default: data.override?.[el] || '',
            [el]: data.override?.[el] || '',
            type: "functional"
        }
    })
    return dto;
}