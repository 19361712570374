<template>
  <v-app
    :is="layout"
    :class="'theme'- + $store.state.layout.theme"
  >
    <v-snackbar
      v-model="isShow"
      transition="slide-y-transition"
    >
      {{ message }}
    </v-snackbar>
    <router-view />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'App',
    computed: {
        ...mapState('alert', {
            message: 'message',
            isShow: 'isShow',
        }),
        layout() {
            return (this.$route.meta.layout || 'admin-layout');
        },
    },
    created(){
        if(!localStorage.getItem('token') && this.$route.path !== '/auth'){
            this.$router.push('/auth')
        } else if(localStorage.getItem('token') && this.$route.path !== '/'){
            this.$router.push('/')
        }
    }
}
</script>