const languages = [{
    flag: "us",
    language: "en",
    title: "English"
},
{
    flag: "es",
    language: "es",
    title: "Castilla"
},
{
    flag: "fr",
    language: "fr",
    title: "France"
},
]

export default languages;