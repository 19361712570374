//colors in theme.scss
const themeColors = [
    {title: "light", color: "white"},
    {title: "dark", color: "black"},
    {title: "purple",},
    {title: "red",},
    {title: "blue",},
    {title: "cyan",},
    {title: "green",},
    {title: "amber",},
    {title: "orange",},
    {title: "brown",},
    {title: "grey",},
];
export default themeColors;