var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"header",class:_vm.$store.state.layout.theme,attrs:{"app":""}},[_c('div',{staticClass:"header__wrap flex-center"},[_c('div',{staticClass:"header__item"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.username)+" ")])]}}])},[_c('v-list',_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,staticClass:"cursor-pointer"},[_c('v-list-item-title',{staticClass:"flex-center flex-nowrap",on:{"click":item.onClick}},[_c('v-icon',{style:({ flex: 0, marginRight: '4px' }),domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")],1)],1)}),1)],1)],1),_c('div',{staticClass:"header__item-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t("label.admin")))])]),_c('div',{staticClass:"flex-center header__item-select"},[_c('v-select',{attrs:{"items":_vm.languages,"item-value":"language","item-text":"flag","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('button',{key:index},[_c('flag',{attrs:{"iso":item.flag,"squared":false}}),_vm._v(" "+_vm._s(item.title)+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('button',{key:index},[_c('flag',{attrs:{"iso":item.flag,"squared":false}}),_vm._v(" "+_vm._s(item.title)+" ")],1)]}}]),model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1),_c('div',{staticClass:"header__item flex-0"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","ripple":false,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}])},[_c('v-list',_vm._l((_vm.themeColors),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.onChangeTheme(item)}}},[_c('v-list-item-title',{staticClass:"flex-center"},[_c('div',{staticClass:"text-center bordered",class:item.color || item.title},[_c('span',{staticClass:"hidden"},[_vm._v("ipsip")])]),_vm._v("   "+_vm._s(_vm.$t('label.'+item.title))+" ")])],1)}),1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }