<template>
  <v-row
    no-gutters
    class="panel__tree min-vh-full"
  >
    <!-- PANEL LEFT -->
    <PanelLeft />

    <!-- PANEL RIGHT -->
    <PanelRight />
  </v-row>
</template>

<script>
import PanelLeft from './PanelLeft.vue';
import PanelRight from './PanelRight.vue';

export default {
    name: 'PanelTree',
    components: {
        PanelLeft,
        PanelRight,
    },
}
</script>
<style lang="scss">
@import '@/styles/theme/variable.scss';
.panel__tree{
    height: 100%;
}
</style>