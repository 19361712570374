import { axiosAdmin } from "../httpHandle";

class Utils {
    /**
     * GET REQUEST
     * @param {String} endpoint
     * @param {Object} pathVars
     * @return {Object} return res
     */

    static wrapperRequestGet = async (types, endpoint = '', pathVars = {}, queryParams = {}) => {

        let mapUrlApi = endpoint;
        Object.keys(pathVars).forEach(pathVar => {
            mapUrlApi = mapUrlApi.replace(`:${pathVar}`, pathVars[pathVar])
        })
        axiosAdmin.defaults.types = types;
        try {
            const res = await axiosAdmin.get(mapUrlApi, {
                queryParams
            });
            return res.data;
        } catch (err) {
            console.log(err);
            return err
        }
    }

    /**
     * POST/PUT/DELETE REQUEST
     * @param {String} endpoint
     * @param {Object} pathVars
     * @return {Object} return res
     */
    static wrapperRequestPost = async (types, endpoint = '', pathVars = {}, data = {}, type = 'post', headers = {}) => {
        
        let mapUrlApi = endpoint;
        Object.keys(pathVars).forEach(keyParams => {
            mapUrlApi = mapUrlApi.replace(`:${keyParams}`, pathVars[keyParams])
        })
        axiosAdmin.defaults.types = types;
        try {
            let res = null;
            switch (type) {
                case 'put': res = await axiosAdmin.put(mapUrlApi, data, headers); break;
                case 'delete': res = await axiosAdmin.delete(mapUrlApi, data, headers); break;
                default: res = await axiosAdmin.post(mapUrlApi, data, headers);
            }
            
            return res;
        } catch (err) {
            console.log(err);
            return err;
        }
    }
}

export default Utils;