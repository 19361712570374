import jwt_decode from "jwt-decode";
import {
    login
} from "../../services/auth/auth.service";
import router from '../../router'


const authModule = {
    state: {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user') || null),
    },
    mutations: {
        setData: (state, {token, user}) => {
            state.token = token;
            state.user = user;
        },
    },
    actions: {
        async login(context, payload){
            let res = await login(payload);
            const token = res?.data?.token;
            if(token){
                let user = jwt_decode(token); 
                localStorage.setItem('user', JSON.stringify(user)); 
                localStorage.setItem('token', token);
                context.commit("setData", {token, user});
            }
            return token;
        },
        async logout(context){
            localStorage.removeItem('user'); 
            localStorage.removeItem('token');
            context.commit("setData", {token: '', user: ''});
            router.push('/auth');
        },
    }
}

export default authModule;