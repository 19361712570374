<template>
  <v-app
    :class="'theme-' + $store.state.layout.theme"
    class="layout__auth flex-center-full"
  >
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {
    name: 'AuthLayout',
}
</script>

<style scoped lang="scss">
@import "@/styles/theme/variable.scss";
.layout__auth{
    display: flex;
    background: inherit !important;
}
</style>
