<template>
  <v-form 
    ref="form"
    v-model="valid"
  >
    <v-container>
      <v-row
        v-for="(value, key) in selectedItemRender"
        :key="key"
      >
        <template v-if="!value.hidden">
          <v-col
            class="text-right"
            tag="strong"
            cols="4"
          >
            <v-subheader class="justify-end">
              {{ $t(`label.${value.label}`) }}
              {{ value.sub }}
              :
            </v-subheader>
          </v-col>

          <!-- define how to show data base on type -->
          <v-col
            v-if="!isEdit"
            class="flex-center"
          >
            <div v-html="renderField(value, key)" />
          </v-col>
          <!-- END: define how to show data base on type -->

          <!-- define how to show data base on type -->
          <v-col v-else>
            <!-- TYPE: select -->
            <v-select
              v-if="value.type === 'select'"
              v-model="value[key]"
              :items="items[key]"
              @change="onChange($event, key)"
            />

            <!-- TYPE: phone -->
            <vue-tel-input
              v-else-if="value.type === 'phone'"
              v-model="value[key]"
            />

            <!-- TYPE: text -->
            <v-text-field
              v-else
              v-model="value[key]"
              class="pt-0"
              :rules="rules[key]"
            />
          </v-col>
          <!-- END: define how to show data base on type -->
        </template>
      </v-row>
    </v-container>
    <div class="form__actions">
      <v-btn
        v-if="isEdit"
        :loading="loading"
        color="success"
        :disabled="!valid || loading"
        @click="onSubmit"
      >
        {{ $t("label.save") }}
        <v-icon right>
          mdi-content-save
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        depressed
        @click="isEdit = true"
      >
        {{ $t("label.edit") }}
        <v-icon right>
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import {
    mapState
} from 'vuex';
import formMixin from '@/mixin/formMixin.js';
import { countryCities } from '@/constants/countries.js';
import { VueTelInput } from 'vue-tel-input';
import { typesPending } from '../../services/app/panel.service';
import { panelType } from '../../store/modules/panel/constant';

export default {
    name: 'InformationForm',
    components: {
        VueTelInput,
    },
    mixins: [formMixin],
    data(){
        return {
            isEdit: false,
            rules: [],
            items: {
                country: countryCities,
                city: []
            },
        }
    },
    computed: mapState({
        selectedItemRender(state){
            let obj = {
                ...state.panel.detailItems?.[0],
            };
            //set value for city for the first time
            obj.country && this.getCities(obj.country.default); //do not get from obj.country.country

            this.isEdit = false; //reset edit state
            return obj
        },
        loading(state){
          const isLoad = state.loader[typesPending[panelType.panelForm]];
          return isLoad;
        },
        isUpdateSuccess(state){
          return state.panelForm?.status?.success;
        }
    }),
    watch: {
        isEdit: function (value) {
            if(value && !this.rules.length){
                let rules = [];
                Object.keys(this.selectedItemRender).forEach(key => {
                    rules[key] = this.getRules(this.selectedItemRender[key].rule)
                })
                this.rules = rules;
            }
        },
        isUpdateSuccess: function (value) {
          if(value) this.isEdit = false
        }
    },
    methods: {
        onSubmit(){
            this.validate();
            if(this.valid){
              const data = Object.keys(this.selectedItemRender).reduce((a,b) => {
                return Object.assign(a, {[b]: this.selectedItemRender[b]?.[b] || this.selectedItemRender[b]?.default} );
              }, {})
              const dataJSON = JSON.stringify(data);
              this.$store.dispatch("panelForm/submitForm", {
                data: dataJSON,
                tenantID: this.$store.state?.panel?.selectedTenant?.ID,
                params: {
                  section: data.section,
                  originalData: data,
                }
              })
            }
        },
        getCities(value){
            this.items.city = countryCities.find(el => el.value === value)?.city;
        },
        onChange(value, key){
            if(key === "country"){
                this.getCities(value);
            }
        },
        renderField(value, key){
          switch (value.type){
            case 'link': return `<a href="${value[key]}">${value[key]}</a>`
            case 'functional': return value.showOnlyOriginal ? `<div>
                  ${value.originalData}              
              </div>`
              :
              `<div>
                <b>
                  ${value[key]} 
                </b>
                <span>
                  (${this.$t(`label.default`)}: ${value.originalData})
                </span>
              </div>`
            default: return `<span>${value[key]}</span>`
          }
        }
    }
}
</script>
<style lang="scss">
@import '@/styles/theme/variable.scss';
.form__actions{
    position: fixed;
    bottom: #{$footer-height};
    right: calc((100% - #{$screen-max-width}) / 2);
    @media screen and (max-width: $screen-max-width) {
        right: 0;
    }
}
</style>