<template>
  <!-- PANEL LEFT -->
  <v-col
    cols="4"
    class="panel__left"
  >
    <v-stepper
      :key="stepPanelIndex"
      v-model="stepPanelIndex"
    >
      <v-stepper-header>
        <template v-for="(step, i) in stepPanelArr">
          <v-stepper-step
            :key="`${i}-step`"
            :step="i"
            editable
            @click="setActivePanel(i)"
          >
            <div
              class="w-100 white--text align-end bg-dark"
            >
              <v-card-title 
                class="text-h6"
              >
                <v-icon 
                  v-if="i !== 0"
                  dark 
                  left 
                  @click="onBack(stepPanelIndex - 1)"
                >
                  mdi-arrow-left
                </v-icon>
                <div class="text-truncate">
                  {{ step.title }}
                </div>
              </v-card-title>
            </div>
          </v-stepper-step>
        </template>
      </v-stepper-header>
      <v-stepper-items class="pannel__left_wrapper">
        <v-progress-circular
          v-show="loadList"
          :size="30"
          color="primary"
          class="my-3 w-100 h-25"
          indeterminate
        />
        <template v-for="(step, i) in stepPanelArr">
          <v-stepper-content 
            :key="i" 
            :step="i"
          >
            <Treeview
              :get-list="getList"
              :list="step.list"
              @onChangeActive="onChangeActive"
            />
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </v-col>
</template>

<script>
import {
  mapGetters,
  mapState
} from 'vuex';
import Treeview from '../common/Treeview.vue'

export default {
    name: 'PanelLeft',
    components: {
      Treeview,
    },
    mixins: [],
    data(){
        return {
            loadList: true,
        }
    },
    computed: {
      ...mapState({
        active: (state) => state.panel.activePanelItems,
        stepPanelIndex: (state) => state.panel.stepPanelIndex,
        activePanelItems: (state) => state.panel.activePanelItems,
        detailItems: (state) => state.panel.detailItems,
      }),
      ...mapGetters(['stepPanelArr'])
    },
    mounted() {
        this.getList();
    },
    methods: {
        onBack(index){
          this.$store.commit("setIsBack", true)
          this.setActivePanel(index);
          this.removeActiveAndItemDetail(index)
        },
        removeActiveAndItemDetail(index){
          let newArrActive = Array.isArray(this.activePanelItems) ? [...this.activePanelItems] : [];
          let newArrDetailItems = Array.isArray(this.detailItems) ? [...this.detailItems] : [];
          newArrDetailItems.shift();
          if(!newArrActive?.[0]?.children_tree){
            newArrDetailItems.shift();
          } else if(index === 0) {
            //reset
            newArrDetailItems = [];
            newArrActive = []
          }
          newArrActive.shift();
          this.$store.commit("setActives", newArrActive);
          this.$store.commit("setItems", newArrDetailItems);
        },
        setActivePanel(index){
          this.$store.commit("setStepPanelIndex", index)
        },
        onChangeActive(newValue) {
          this.$store.commit("setActives", newValue);
          this.$store.commit("setIsBack", false)
        },
        async getList(item = {}) {
            const id = item.ID;
            if(id) {
                await this.$store.dispatch("getTopicList", item);
            } else {
                await this.$store.dispatch("getTenantList");
                this.loadList = false;
            }
        },
    },
}
</script>
<style lang="scss">
@import '@/styles/theme/variable.scss';
.panel__left{
    position: fixed;
    top: #{$header-height};
    bottom: #{$footer-height};
    left: calc((100% - #{$screen-max-width}) / 2);
    width: calc(#{$screen-max-width * 0.3333}) !important; //col4
    .v-stepper{
      box-shadow: none !important;
      // overflow: unset !important;
      .v-stepper__header{
        box-shadow: none;
        justify-content: flex-start;
        flex-wrap: nowrap;
        height: unset;
        width: 200%;
        .text-truncate{
          width: calc(#{$screen-max-width * 0.3333} - 60px); //col4
        }
        .v-stepper__step {
          display: none;
          padding: 0;
          min-width: calc(#{$screen-max-width * 0.3333}) !important; //col4
          &--active{
            display: block;
          }
          .v-stepper__step__step{
            display: none;
          }
          .v-stepper__label {
            width: 100%;
            @media only screen and (max-width: 959px){
              display: flex
            }
          }
          .v-responsive__content{
            width: 100%;
          }
        }
      }
      .v-stepper__items{
        overflow: auto;
        height: calc(#{$sreen-full-height} - 74px);
        .v-stepper__content{
          padding: 0;
        }
      }
    }
    .pannel__left_wrapper{
        min-height: calc(#{$sreen-full-height} - 74px);
    }
    @media screen and (max-width: $screen-max-width) {
        left: 0;
    }
}
</style>